<template>
  <div class="All">
    <div class="search">
      <md-icon class="back" @click="back" name="arrow-left"></md-icon>
      <div class="searchline clearfix">
        <button class="btnicon">
          <md-icon name="search" size="lg" color="#D6D6D6"></md-icon>
        </button>
        <input
          v-on:focus="cleardatafocus"
          type="text"
          v-model="formlist.cpname"
          placeholder="请输入产品名称"
        />
        <button @click="clearseadata" v-show="cleardatatrue" class="btnclear">
          <md-icon name="fail" size="lg" color="#666"></md-icon>
        </button>
      </div>
      <div @click="search_ff" class="searchTxt">搜索</div>
    </div>
    <div class="search_history">
      <div>搜索历史</div>
      <md-icon @click="del" name="delete"></md-icon>
    </div>
    <div class="roll">
      <div
        v-for="(item, index) in JSON.parse(getStorage('history'))"
        :key="index"
        class="history"
      >
        <div @click="cli(item, index)">{{ item }}</div>
        <md-icon @click="detail(item, index)" name="close"></md-icon>
      </div>
    </div>
  </div>
</template>
<script>
import { getStorage, setStorage, isAndroid } from "@/lib/util";
export default {
  data() {
    return {
      listpageNo: 1, //当前页数
      pageSize: 8, //当前页数的数据量
      cleardatatrue: false, //输入框中的×是否出现
      // 接口需要传的参数
      formlist: {
        baseid: "",
        cpname: "",
      },
      gj_history: [], //存至缓存
    };
  },
  created() {
    if (!getStorage("history")) {
      setStorage("history", []);
    } else {
      this.gj_history = JSON.parse(getStorage("history"));
    }
  },
  methods: {
    // 返回产品列表
    back() {
      this.$router.push("/productlist");
    },
    cleardatafocus() {
      // 输入框中的×出现
      this.cleardatatrue = true;
    },
    // 点击输入框中的×清除数据
    clearseadata() {
      this.formlist.cpname = "";
      this.listpageNo = 1;
      this.reportList = [];
      // 输入框中的×隐藏
      this.cleardatatrue = false;
    },
    // 点击搜索的事件
    search_ff() {
      if (this.gj_history.length >= 20) {
        this.gj_history.splice(0, 1);
      }
      let search_name = this.formlist.cpname.replace(/\s+/g, "");
      if (search_name.length > 0) {
        this.gj_history.unshift(this.formlist.cpname);
        this.gj_history = [...new Set(this.gj_history)]; //去重
        setStorage("history", this.gj_history);
      }
      this.$router.push({
        path: "/productlist",
        query: {
          name: this.formlist.cpname,
        },
      });
    },
    // 点击搜索历史右边的删除 清空搜索历史
    del() {
      setStorage("history", []);
      this.$forceUpdate();
    },
    // 删除单个的搜索历史
    detail(item, index) {
      this.gj_history.splice(index, 1);
      setStorage("history", this.gj_history);
      this.$forceUpdate();
    },
    // 点击搜索历史的关键字回显到输入框中
    cli(item, index) {
      this.formlist.cpname = item;
    },
  },
};
</script>
<style lang="stylus" scoped>
.All {
  padding: 0.4rem 0.3rem 0;
}

.search {
  display: flex;
  justify-content: space-between;

  .md-icon.icon-font.md {
    font-size: 0.5rem;
  }

  >i {
    align-self: center;
  }

  .searchline {
    border-radius: 0.1rem;
    background: #F2F2F2;
    height: 0.87rem;
    width: 75%;
    margin-left: 6%;
  }

  .btnicon {
    width: 8%;
    height: 0.87rem;
    border-radius: 0.3rem 0 0 0.3rem;
    background: none;
    margin-left: 0.2rem;
  }

  input {
    height: 0.87rem;
    border: none;
    background: none;
    font-size: 0.33rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #A5A5A5;
    position: absolute;
    color: black;
    width: 60%;
  }

  .btnclear {
    background: none;
    position: absolute;
    right: 1.4rem;
    line-height: 0.95rem;
    margin-right: 0.4rem;
  }

  .searchTxt {
    font-size: 0.36rem;
    color: #474747;
    margin-left: 0.25rem;
    align-self: center;
  }
}

.search_history, .history {
  display: flex;
  justify-content: space-between;
  margin-top: 0.6rem;

  div {
    align-self: center;
    font-size: 0.5rem;
    font-weight: 700;
  }

  .md-icon.icon-font.md {
    font-size: 0.5rem;
  }

  i {
    align-self: center;
    font-size: 0.45rem;
  }
}

.history {
  div {
    font-size: 0.45rem;
    font-weight: 500;
    width: 90%;
  }

  i {
    font-size: 0.3rem !important;
  }
}

.roll {
  height: 80vh;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none; // 重点
  }
}
</style>

